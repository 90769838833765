import { FC } from 'react';

import { FlowLayout } from '@/common/flow/flow-layout';
import { MultiStepFlow } from '@/common/multi-step-flow';

import { HStack, Text, Em } from '@ui-furniture/elements';
import { ModalControls } from '@ui-furniture/lib/modal/types/controls';
import { SolidIcon } from '@ui-furniture/ui/solid-icon';

import { MipProvider } from '../context/mip-provider';
import { AppointmentBooking } from '../ui/appointment-booking';
import { Complete } from '../ui/complete';
// import { ContactDetails } from '../ui/contact-details';
import { HasOfferAgreed } from '../ui/has-offer-agreed';
import { Introduction } from '../ui/introduction';
import { IsSorted } from '../ui/is-sorted';
import { WhatAreYouLookingFor } from '../ui/what-are-you-looking-for';

export const MortgageMenu = () => {
  return (
    <HStack alignItems='center'>
      <SolidIcon name='coinStack' size='lg' />
      <Text fontWeight='700'>
        Find a <Em>mortgage</Em>
      </Text>
    </HStack>
  );
};

export const MortgageFlow: FC<{
  controls: ModalControls;
}> = ({ controls }) => {
  return (
    <FlowLayout controls={controls} menu={<MortgageMenu />}>
      <MipProvider>
        <MultiStepFlow
          flowName='book-mip-appointment'
          close={controls.close}
          steps={[
            {
              id: 'intro',
              Screen: Introduction,
            },
            {
              id: 'has-offer-agreed',
              Screen: HasOfferAgreed,
            },
            {
              id: 'is-sorted',
              Screen: IsSorted,
            },
            {
              id: 'what-are-you-looking-for',
              Screen: WhatAreYouLookingFor,
            },
            // {
            //   id: 'contactDetails',
            //   Screen: ContactDetails,
            // },
            {
              id: 'appointment-booking',
              Screen: AppointmentBooking,
            },
            {
              id: 'complete',
              Screen: Complete,
            },
          ]}
        />
      </MipProvider>
    </FlowLayout>
  );
};
