import {
  StepContainer,
  StepComponentAnimator,
  useStepContext,
} from '@/common/multi-step-flow';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { Text, VStack } from '@ui-furniture/elements';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';
import { useStateModalControls } from '@ui-furniture/lib/modal';

import { UnavailableModal } from '../modals/unavailable-modal';
import { OptionCard } from '../option-card';

export const HowToCompleteTask = () => {
  const { next } = useStepContext();
  const { completeFlowSuccessfully } = useFlowControls();
  const { reportEvent } = useAnalyticsReportingService();
  const modalControls = useStateModalControls();

  const handleWithAdvisor = () => {
    if (modalControls.isOpen) {
      modalControls.close();
    }
    reportEvent({
      name: 'MIP - How to Complete Task',
      value: 'with an advisor',
    });
    next();
  };

  const handleOnline = () => {
    reportEvent({
      name: 'MIP - How to Complete Task',
      value: 'online',
    });
    modalControls.open();
  };

  const handleDecline = () => {
    modalControls.close();
    completeFlowSuccessfully({
      callbackName: 'book-mip-appointment',
      conversion: false,
    });
  };

  return (
    <StepContainer>
      <VStack spacing='2xl' alignItems='center'>
        <StepComponentAnimator>
          <Text variant='title' as='h2'>
            How would you like to sort your mortgage?
          </Text>
          <VStack spacing='lg'>
            <Text>
              You can either search through all major mortgage products
              yourself, or book a free, quick call with an expert at a time that
              suits you, and leave them to do the searching for you.
            </Text>
            <Text>
              We recommend taking some free advice from a qualified professional
              to ensure you get the best deal.
            </Text>
          </VStack>
        </StepComponentAnimator>
        <StepComponentAnimator>
          <VStack spacing='xl'>
            <OptionCard
              onClick={handleWithAdvisor}
              title='I want a mortgage broker to find me the best deal'
              description="Book a call with an expert at a time that suits you. They won't charge you a penny, have access to exclusive products, and saves you the hassle."
              recommendation='For busy people'
            />
            <OptionCard
              onClick={handleOnline}
              title="I'll do the research myself"
              description='Select a mortgage from the options available in the market. Mortgages can be complicated and time consuming so we recommend you speak with an expert.'
              recommendation='For the perfectionist'
            />
          </VStack>
        </StepComponentAnimator>
      </VStack>
      <UnavailableModal
        modalControls={modalControls}
        onAccept={handleWithAdvisor}
        onDecline={handleDecline}
      />
    </StepContainer>
  );
};
