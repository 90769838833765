import { FC, useCallback } from 'react';

import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

import {
  StepComponentAnimator,
  StepContainer,
  useStepContext,
} from '@/common/multi-step-flow';
import config from '@/config';

import { useCreateMiPMutation } from '@graphql/requests';

import { apiRestClient } from '@ui-furniture/clients/api-rest-client';
import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { errorService } from '@ui-furniture/common/services/error';
import { useToast } from '@ui-furniture/common/toast';
import { Text, VStack } from '@ui-furniture/elements';
import { useCurrentUser } from '@ui-furniture/hooks/use-current-user';
import { LineIcon } from '@ui-furniture/ui/line-icon';

import { useMipContext } from '../hooks/use-mip-context';

const CALENDLY_LINK =
  config.env === 'production'
    ? 'https://calendly.com/home-me-mortgages/housecure-mortgage-advice-call'
    : 'https://calendly.com/hs-bot/30min';

export const AppointmentBooking: FC = () => {
  const { collectionKey, mipData } = useMipContext();
  const { currentUser } = useCurrentUser();
  const { next } = useStepContext();
  const { updateMipData } = useMipContext();
  const { reportEvent } = useAnalyticsReportingService();
  const [createMip] = useCreateMiPMutation({
    refetchQueries: ['GetPurchase'],
  });
  const { showToast } = useToast();

  const createMipAndNext = useCallback(async () => {
    try {
      if (collectionKey) {
        await createMip({
          variables: {
            collectionKey,
          },
        });
      }
      updateMipData({ isAppointmentBooked: true });
      await apiRestClient.sendSlackNotification('mortgage-appointment-booked', {
        ...mipData,
      });
      reportEvent({
        name: 'MIP - Appointment Booked',
        value: true,
      });
      next();
    } catch (e) {
      showToast({
        severity: 'error',
        message: `Something went wrong. Please try again later.`,
      });
      errorService.captureException(e);
    }
  }, []);

  useCalendlyEventListener({
    onEventScheduled: () => createMipAndNext(),
  });

  return (
    <StepContainer>
      <StepComponentAnimator>
        <LineIcon name='bankSquare' size='xl' color='primary' />
        <Text variant='title'>Book an appointment</Text>
      </StepComponentAnimator>
      <StepComponentAnimator>
        <VStack my='xl' spacing='xl'>
          <Text>
            Great! Our expert mortgage broker can help you out in a 30 minute
            call at a time that suits you. They can answer any questions you
            have whilst finding you the best mortgage deal for you.
          </Text>
          <Text>Book in a mortgage consultation below.</Text>
        </VStack>
        <InlineWidget
          url={CALENDLY_LINK}
          prefill={{
            name: currentUser?.preferredName || undefined,
            email: currentUser?.email || undefined,
          }}
          pageSettings={{
            backgroundColor: 'faf8f7',
            hideEventTypeDetails: true,
            primaryColor: 'eb6e46',
            textColor: '374668',
            hideGdprBanner: true,
          }}
        />
      </StepComponentAnimator>
    </StepContainer>
  );
};
