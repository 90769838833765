import { PropsWithChildren } from 'react';

import { StyledComponent, x } from '@ui-furniture/style';

import { IconName, SolidIcon } from './solid-icon';

const variants = {
  bright: {
    h: '28px',
    borderRadius: '14px',
    backgroundColor: 'palette-primary',
    color: 'palette-primary-text',
  },
  plain: {
    borderRadius: 'sm',
    border: '1px solid',
    borderColor: 'palette-border',
  },
  highlight: {
    h: '28px',
    borderRadius: '14px',
    background:
      'linear-gradient(216deg, #FAE3AE 21.01%, #F0CD7C 42.49%, #EFE8D8 88.13%)',
    border: '1px solid',
    borderColor: 'brand-yellow',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: 1.2,
    fontSize: 'sm',
  },
};

export const Chip: StyledComponent<
  PropsWithChildren<{
    variant?: keyof typeof variants;
    iconName?: IconName;
  }>
> = ({ children, variant = 'bright', iconName, ...rest }) => (
  <x.span
    py='xs'
    px='md'
    display='flex'
    alignItems='center'
    justifyContent='center'
    whiteSpace='nowrap'
    gap='xs'
    {...variants[variant]}
    {...rest}
  >
    {iconName ? <SolidIcon name={iconName} size='22px' /> : null}
    <x.span position='relative' top='2px'>
      {children}
    </x.span>
  </x.span>
);
