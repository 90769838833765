import { FC, ReactNode } from 'react';

import { VStack } from '@ui-furniture/elements';
import { x } from '@ui-furniture/style';
import { ChildAnimation } from '@ui-furniture/ui/animation/child';

import { SideMenuHeader } from './side-menu-header';

const variants = {
  regular: {
    w: '320px',
    position: 'relative',
  },
  flow: {
    w: '262px',
  },
};

export const SideMenu: FC<{
  title?: string;
  customHeader?: ReactNode;
  content?: ReactNode;
  py?: string;
  px?: string;
  variant?: keyof typeof variants;
}> = ({ title, py = '0', customHeader, content, variant = 'regular' }) => {
  return (
    <x.div {...variants[variant]} aria-label='side-menu' h='100%'>
      <VStack minH='100%' py={py}>
        <ChildAnimation>
          <SideMenuHeader title={title} customHeader={customHeader} />
        </ChildAnimation>
        {content}
      </VStack>
    </x.div>
  );
};
