import { FC } from 'react';

import { StepComponentAnimator, StepContainer } from '@/common/multi-step-flow';

import { HStack, Button, Text, VStack } from '@ui-furniture/elements';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';
import { LineIcon } from '@ui-furniture/ui/line-icon';

export const CompleteAfterBooking: FC = () => {
  const { completeFlowSuccessfully } = useFlowControls();

  return (
    <StepContainer>
      <StepComponentAnimator>
        <LineIcon name='checkCircle' size='xl' color='primary' />
        <Text variant='title' as='h2'>
          The appointment is booked
        </Text>
      </StepComponentAnimator>
      <StepComponentAnimator>
        <VStack my='xl'>
          <Text fontWeight='bold'>
            You’ll receive an email with further details about what you should
            have prepared for the meeting such as income information.
          </Text>
          <Text>
            The email will also include a calendar invite that will provide a
            reminder shortly before the meeting starts.
          </Text>
        </VStack>
      </StepComponentAnimator>
      <HStack>
        <Button
          type='button'
          onClick={() =>
            completeFlowSuccessfully({
              callbackName: 'book-mip-appointment',
              conversion: true,
            })
          }
        >
          Done
        </Button>
      </HStack>
    </StepContainer>
  );
};
