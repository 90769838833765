import { FC } from 'react';

import {
  StepComponentAnimator,
  StepContainer,
  useStepContext,
} from '@/common/multi-step-flow';

import { Text, VStack, Button } from '@ui-furniture/elements';
import { KeyPointsGrid } from '@ui-furniture/ui/key-points-grid';
import { LineIcon } from '@ui-furniture/ui/line-icon';

export const Introduction: FC = () => {
  const { next } = useStepContext();

  return (
    <StepContainer>
      <StepComponentAnimator>
        <LineIcon name='bankSquare' size='xl' color='primary' />
        <Text variant='title' as='h2'>
          Start organising your mortgage
        </Text>
        <Text mt='lg'>
          Mortgages can take a lot of time and effort to organise. Luckily,
          HousecureConnect speeds the process up by showing you the best
          mortgage products, or providing you with an expert to advise on your
          transaction.
        </Text>
      </StepComponentAnimator>
      <StepComponentAnimator>
        <VStack my='xl' spacing='lg' maxW='100%'>
          <KeyPointsGrid
            title='We first need to confirm where you are in your journey'
            icon='journey'
          >
            Whether you&apos;ve just started looking or you&apos;ve put an offer
            down on your new home, we can recommend your next step.
          </KeyPointsGrid>
          <KeyPointsGrid
            title='Choose how you want to find the right mortgage'
            icon='searchList'
          >
            Everyone is different. Whether you&apos;d like to speak to an expert
            for some free, impartial advice or take a look at mortgage products
            yourself, we&apos;ve got you covered.
          </KeyPointsGrid>
        </VStack>
      </StepComponentAnimator>
      <StepComponentAnimator>
        <Button aria-label='start' type='button' onClick={next}>
          Let&#39;s get started
        </Button>
      </StepComponentAnimator>
    </StepContainer>
  );
};
