import {
  StepContainer,
  StepComponentAnimator,
  useStepContext,
} from '@/common/multi-step-flow';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { HStack, Text, Button, VStack } from '@ui-furniture/elements';

import { useMipContext } from '../../hooks/use-mip-context';

export const HasMip = () => {
  const { next, setPosition } = useStepContext();
  const { updateMipData } = useMipContext();
  const { reportEvent } = useAnalyticsReportingService();

  const handleYes = () => {
    updateMipData({ hasMip: true });
    reportEvent({
      name: 'MIP - Has MIP',
      value: true,
    });
    setPosition(5);
  };

  const handleNo = () => {
    updateMipData({ hasMip: false });
    reportEvent({
      name: 'MIP - Has MIP',
      value: false,
    });
    next();
  };

  return (
    <StepContainer>
      <VStack spacing='xl' alignItems='center'>
        <StepComponentAnimator>
          <Text variant='title' as='h2'>
            Do you already have a mortgage in principle?
          </Text>
          <VStack spacing='lg' my='lg'>
            <Text>
              Don&apos;t know what a mortgage in principle is? Then you probably
              don&apos;t have one, but also it&apos;s nothing to worry about.
            </Text>
            <Text>
              It is the closest you can get to confirming how much you can
              afford and at what interest rate. It shows to sellers you are
              prepared and converting it to a mortgage should be fairly
              straightforward.
            </Text>
          </VStack>
        </StepComponentAnimator>
        <StepComponentAnimator>
          <HStack spacing='xl'>
            <Button type='button' version='secondary' onClick={handleNo}>
              No
            </Button>
            <Button type='button' version='secondary' onClick={handleYes}>
              Yes
            </Button>
          </HStack>
        </StepComponentAnimator>
      </VStack>
    </StepContainer>
  );
};
