import { StyledComponent, createVariants, x } from '@ui-furniture/style';

const variants = createVariants({
  dashboard: {
    display: 'flex',
    flexDirection: 'column',
    w: '100%',
  },
});

export const PanelContainer: StyledComponent<{
  variant?: keyof typeof variants;
}> = ({ variant = 'dashboard', ...rest }) => (
  <x.div {...variants[variant]} {...rest} />
);
