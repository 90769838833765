import { FC } from 'react';

import { VStack, Text, HStack, Button } from '@ui-furniture/elements';
import { Modal } from '@ui-furniture/lib/modal';
import { ModalControls } from '@ui-furniture/lib/modal/types/controls';

type Props = {
  modalControls: ModalControls;
  onAccept: () => void;
  onDecline: () => void;
};

export const UnavailableModal: FC<Props> = ({
  modalControls,
  onAccept,
  onDecline,
}) => {
  return (
    <Modal controls={modalControls} variant='popover'>
      <VStack spacing='xl' alignItems='center'>
        <VStack spacing='lg'>
          <Text>
            Sorry, we are still working on this option. It&apos;s coming very
            soon!
          </Text>
          <Text>
            Don&apos;t worry, our partners have access to all the major mortgage
            products and give you free advice on what will suit you best. Unlike
            most advisers, they are completely free.
          </Text>
          <Text>
            Did you know? Mortgage brokers often have direct links with the
            lenders they work with, which can speed up the whole application
            process and provide you with better deals.
          </Text>
        </VStack>
        <HStack justifyContent='center' spacing='xl'>
          <Button type='button' version='secondary' onClick={onDecline}>
            See what else we offer
          </Button>
          <Button type='button' version='primary' onClick={onAccept}>
            Speak to a mortgage adviser
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
};
