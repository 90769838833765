import { FC, ReactElement, PropsWithChildren } from 'react';

import { Modal } from '@ui-furniture/lib/modal';
import { ModalControls } from '@ui-furniture/lib/modal/types/controls';
import { ContentLayout } from '@ui-furniture/ui/layout/content-layout/content-layout';

import { FlowMenu } from './flow-menu';

export const FlowLayout: FC<
  PropsWithChildren<{
    menu?: ReactElement;
    controls: ModalControls;
  }>
> = ({ children, menu, controls }) => {
  return (
    <Modal controls={controls} variant='flow' contentPadding={0} disableClose>
      <ContentLayout menu={<FlowMenu>{menu}</FlowMenu>}>
        {children}
      </ContentLayout>
    </Modal>
  );
};
