import { errorService } from '@ui-furniture/common/services/error';

import { HasMip } from './has-mip';
import { HasMortgage } from './has-mortgage';

import { useMipContext } from '../../hooks/use-mip-context';
import { Oops } from '../oops';

export const IsSorted = () => {
  const { mipData } = useMipContext();

  if (mipData?.hasOfferAgreed === false) {
    return <HasMip />;
  }

  if (mipData?.hasOfferAgreed === true) {
    return <HasMortgage />;
  }

  errorService.captureException(new Error('Invalid hasOfferAgreed value'));

  return <Oops />;
};
