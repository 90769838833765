import { FC, PropsWithChildren } from 'react';

import { Card, HStack } from '@ui-furniture/elements';
import { SideMenu } from '@ui-furniture/ui/layout/side-menu/side-menu';

const CustomHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <HStack w='100%' justifyContent='center' p='md'>
      <Card p='md'>{children}</Card>
    </HStack>
  );
};

export const FlowMenu: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SideMenu
      variant='flow'
      customHeader={<CustomHeader>{children}</CustomHeader>}
    />
  );
};
