import {
  StepContainer,
  StepComponentAnimator,
  useStepContext,
} from '@/common/multi-step-flow';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { HStack, Text, Button, VStack } from '@ui-furniture/elements';

import { useMipContext } from '../../hooks/use-mip-context';

export const HasMortgage = () => {
  const { next } = useStepContext();
  const { updateMipData } = useMipContext();
  const { reportEvent } = useAnalyticsReportingService();

  const handleNext = (hasMortgage: boolean) => {
    updateMipData({ hasMortgage });
    reportEvent({
      name: 'MIP - Has Mortgage',
      value: hasMortgage,
    });
    next();
  };

  return (
    <StepContainer>
      <VStack spacing='xl' alignItems='center'>
        <StepComponentAnimator>
          <Text variant='title' as='h2'>
            Have you already confirmed the mortgage you are getting?
          </Text>
          <Text my='lg'>
            Everyone knows banks are slow. Starting your full mortgage
            application ASAP one of the best ways to speed up your home buying
            journey. Don&apos;t worry- we will make sure you&apos;re well on
            your way in no time.
          </Text>
        </StepComponentAnimator>
        <StepComponentAnimator>
          <HStack spacing='xl'>
            <Button
              type='button'
              version='secondary'
              onClick={() => handleNext(false)}
            >
              No
            </Button>
            <Button
              type='button'
              version='secondary'
              onClick={() => handleNext(true)}
            >
              Yes
            </Button>
          </HStack>
        </StepComponentAnimator>
      </VStack>
    </StepContainer>
  );
};
