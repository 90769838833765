import { CompleteAfterBooking } from './complete-after-booking';
import { CompleteNoBooking } from './complete-no-booking';

import { useMipContext } from '../../hooks/use-mip-context';

export const Complete = () => {
  const { mipData } = useMipContext();

  if (mipData?.isAppointmentBooked === true) {
    return <CompleteAfterBooking />;
  }

  return <CompleteNoBooking />;
};
