import { FC, ReactNode } from 'react';

import { HStack, Text } from '@ui-furniture/elements';

export const SideMenuHeader: FC<{
  title?: string;
  customHeader?: ReactNode;
}> = ({ title, customHeader }) => {
  const hasHeader = title;

  if (customHeader) {
    return <>{customHeader}</>;
  }
  if (hasHeader) {
    return (
      <HStack alignItems='flex-start' p='md'>
        {title ? (
          <Text flex='1' mt='2px' variant='bodyBig'>
            {title}
          </Text>
        ) : null}
      </HStack>
    );
  }
  return null;
};
