import { StepContainer, StepComponentAnimator } from '@/common/multi-step-flow';

import { Text, VStack } from '@ui-furniture/elements';

export const Oops = () => (
  <StepContainer>
    <VStack spacing='4xl' alignItems='center'>
      <StepComponentAnimator>
        <Text variant='title' as='h2'>
          Oops something went wrong...
        </Text>
      </StepComponentAnimator>
    </VStack>
  </StepContainer>
);
