import { FC, PropsWithChildren, useCallback, useState } from 'react';

import { useParamOnMount } from '@ui-furniture/hooks/use-param-on-mount';

import { MipData, mipContext } from './mip.context';

export const MipProvider: FC<PropsWithChildren> = ({ children }) => {
  const collectionKey = useParamOnMount('collectionKey', 'string');
  const [mipData, setMipData] = useState({});

  const updateMipData = useCallback((updatedData: MipData) => {
    setMipData((existingData) => ({
      ...existingData,
      ...updatedData,
    }));
  }, []);

  const key = collectionKey ? `${collectionKey}` : undefined;

  return (
    <mipContext.Provider
      value={{
        collectionKey: key,
        mipData,
        updateMipData,
      }}
    >
      {children}
    </mipContext.Provider>
  );
};
