import { createContext } from 'react';

export type MipData = {
  hasOfferAgreed?: boolean;
  hasMip?: boolean;
  hasMortgage?: boolean;
  mortgageProvider?: string;
  isAppointmentBooked?: boolean;
  contactEmail?: string;
};

export const mipContext = createContext<{
  updateMipData: (data: Partial<MipData>) => void;
  mipData: MipData;
  collectionKey?: string;
} | null>(null);
