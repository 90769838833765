import { FC } from 'react';

import { Card, Hr, Text, VStack } from '@ui-furniture/elements';
import { Chip } from '@ui-furniture/ui/chip';
import { Clickable } from '@ui-furniture/ui/clickable';

export const OptionCard: FC<{
  title: string;
  description: string;
  recommendation: string;
  onClick: () => any;
}> = ({ title, description, recommendation, onClick }) => (
  <Clickable
    action={{
      type: 'button',
      onClick,
    }}
    pt='lg'
  >
    <Card clickStyleVariant='scale' position='relative'>
      <Chip
        position='absolute'
        top='0'
        right='20px'
        transform='translateY(-70%)'
      >
        {recommendation}
      </Chip>
      <VStack spacing='none' m='lg' textAlign='left'>
        <Text variant='button'>{title}</Text>
        <Hr />
        <Text variant='bodySmall'>{description}</Text>
      </VStack>
    </Card>
  </Clickable>
);
