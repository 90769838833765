import { StyledComponent, createVariants, x } from '@ui-furniture/style';

const variants = createVariants({
  center: {
    m: 'auto',
  },
  top: {
    m: 'unset',
  },
});

export const PanelContent: StyledComponent<{
  variant?: keyof typeof variants;
}> = ({ variant = 'center', children, ...rest }) => (
  <x.div
    display='flex'
    flexDirection='column'
    w='100%'
    mx='auto'
    p='lg'
    pt='xl'
    {...rest}
  >
    <x.div w='100%' {...variants[variant]}>
      {children}
    </x.div>
  </x.div>
);
