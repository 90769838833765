import { z } from 'zod';

import {
  StepContainer,
  StepComponentAnimator,
  useStepContext,
} from '@/common/multi-step-flow';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import {
  Form,
  Input,
  SubmitFormHandler,
  useForm,
} from '@ui-furniture/common/form';
import { Text, Button, VStack, HStack } from '@ui-furniture/elements';

import { useMipContext } from '../../hooks/use-mip-context';

const MortgageProviderSchema = z.object({
  mortgageProvider: z.coerce
    .string()
    .min(3, 'Name must be at least 3 characters')
    .max(50, 'Name must be less than or equal to 50 characters'),
});

export type MortgageProviderFormHandler = SubmitFormHandler<
  typeof MortgageProviderSchema
>;

export const WhichMortgageProvider = () => {
  const formHandler = useForm(MortgageProviderSchema, {});
  const { handleSubmit } = formHandler;
  const { reportEvent } = useAnalyticsReportingService();

  const { setPosition } = useStepContext();
  const { updateMipData } = useMipContext();

  const submit: MortgageProviderFormHandler = async (values) => {
    updateMipData({ mortgageProvider: values.mortgageProvider });
    reportEvent({
      name: 'MIP - Mortgage Provider',
      value: values.mortgageProvider,
    });
    setPosition(5);
  };

  const handleSkip = () => {
    reportEvent({
      name: 'MIP - Mortgage Provider',
      value: 'User skipped',
    });
    setPosition(5);
  };

  return (
    <StepContainer>
      <VStack spacing='xl' alignItems='center'>
        <StepComponentAnimator>
          <Text variant='title' as='h2'>
            Great, if you don’t mind us asking, who is it with?
          </Text>
          <Text my='lg'>
            We&apos;re asking mostly so we can be sure we know the best products
            to offer our other customers. Don&apos;t worry if you would prefer
            not to share.
          </Text>
        </StepComponentAnimator>
        <StepComponentAnimator w='100%'>
          <Form form={formHandler} onSubmit={handleSubmit(submit)}>
            <VStack spacing='xl'>
              <Input
                name='mortgageProvider'
                placeholder='Halifax, Santander, etc.'
                label='Your mortgage provider'
              />
              <HStack justifyContent='center'>
                <Button type='button' version='secondary' onClick={handleSkip}>
                  Skip
                </Button>
                <Button type='submit' version='primary'>
                  Next
                </Button>
              </HStack>
            </VStack>
          </Form>
        </StepComponentAnimator>
      </VStack>
    </StepContainer>
  );
};
