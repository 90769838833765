import { FC } from 'react';

import {
  StepComponentAnimator,
  StepContainer,
  useStepContext,
} from '@/common/multi-step-flow';

import { useAnalyticsReportingService } from '@ui-furniture/common/analytics';
import { HStack, Button, Text, VStack } from '@ui-furniture/elements';

import { useMipContext } from '../hooks/use-mip-context';

export const HasOfferAgreed: FC = () => {
  const { next } = useStepContext();
  const { updateMipData } = useMipContext();
  const { reportEvent } = useAnalyticsReportingService();

  const handleNext = (hasOfferAgreed: boolean) => {
    updateMipData({ hasOfferAgreed });
    reportEvent({
      name: 'MIP - Has Offer Agreed',
      value: hasOfferAgreed,
    });
    next();
  };

  return (
    <StepContainer>
      <VStack spacing='xl' alignItems='center'>
        <StepComponentAnimator>
          <Text variant='title' as='h2'>
            Do you already have an offer agreed?
          </Text>
        </StepComponentAnimator>
        <StepComponentAnimator>
          <HStack spacing='xl'>
            <Button
              type='button'
              version='secondary'
              onClick={() => handleNext(false)}
            >
              No
            </Button>
            <Button
              type='button'
              version='secondary'
              onClick={() => handleNext(true)}
            >
              Yes
            </Button>
          </HStack>
        </StepComponentAnimator>
      </VStack>
    </StepContainer>
  );
};
