import { HowToCompleteTask } from './how-to-complete-task';
import { WhichMortgageProvider } from './which-mortgage-provider';

import { useMipContext } from '../../hooks/use-mip-context';

export const WhatAreYouLookingFor = () => {
  const { mipData } = useMipContext();

  if (mipData?.hasOfferAgreed && mipData?.hasMortgage === true) {
    return <WhichMortgageProvider />;
  }

  return <HowToCompleteTask />;
};
