import { Text } from '@ui-furniture/elements';
import { StyledComponent, x } from '@ui-furniture/style';
import { IconName, LineIcon } from '@ui-furniture/ui/line-icon';

export const KeyPointsGrid: StyledComponent<{
  title: string;
  icon: IconName;
}> = ({ title, children, icon }) => {
  return (
    <x.div
      display='grid'
      columnGap='md'
      rowGap='sm'
      gridTemplateColumns='min-content 1fr'
      textAlign='left'
    >
      <x.div display='flex' alignItems='center'>
        <LineIcon name={icon} size='md' color='primary' />
      </x.div>
      <x.div display='flex' alignItems='center'>
        <Text color='primary'>{title}</Text>
      </x.div>
      <x.div gridColumn='2 / span 1'>
        {typeof children === 'string' ? (
          <Text fontSize='xs' textAlign='left'>
            {children}
          </Text>
        ) : (
          children
        )}
      </x.div>
    </x.div>
  );
};
