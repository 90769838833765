import { FC, useCallback } from 'react';

import { StepComponentAnimator, StepContainer } from '@/common/multi-step-flow';

import { useCreateExternalMipMutation } from '@graphql/requests';

import { errorService } from '@ui-furniture/common/services/error';
import { HStack, Button, Text, VStack } from '@ui-furniture/elements';
import { useFlowControls } from '@ui-furniture/hooks/use-flow-controls';
import { LineIcon } from '@ui-furniture/ui/line-icon';

import { useMipContext } from '../../hooks/use-mip-context';

export const CompleteNoBooking: FC = () => {
  const { collectionKey } = useMipContext();
  const { completeFlowSuccessfully } = useFlowControls();
  const [createExternalMip] = useCreateExternalMipMutation({
    refetchQueries: ['GetPurchase'],
  });

  const handleComplete = useCallback(async () => {
    try {
      await createExternalMip({
        variables: {
          collectionKey: collectionKey!,
        },
      });

      completeFlowSuccessfully({
        callbackName: 'book-mip-appointment',
        conversion: false,
      });
    } catch (e) {
      errorService.captureException(e);
    }
  }, []);

  return (
    <StepContainer>
      <StepComponentAnimator>
        <LineIcon name='checkCircle' size='xl' color='primary' />
        <Text variant='title' as='h2'>
          Amazing, it looks like you are really prepared!
        </Text>
        <Text my='xl'>
          There’s nothing more we can do for you at the moment.
        </Text>
      </StepComponentAnimator>
      {collectionKey && (
        <StepComponentAnimator>
          <VStack alignItems='center'>
            <Text fontWeight='bold'>
              Would you like to mark this task as complete?
            </Text>

            <HStack>
              <Button
                type='button'
                version='secondary'
                onClick={() =>
                  completeFlowSuccessfully({
                    callbackName: 'book-mip-appointment',
                    conversion: false,
                  })
                }
              >
                No
              </Button>
              <Button type='button' onClick={handleComplete}>
                Yes
              </Button>
            </HStack>
          </VStack>
        </StepComponentAnimator>
      )}
    </StepContainer>
  );
};
